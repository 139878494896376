import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useAuth } from '@/features/auth/context/auth';
import { COOKIE_ACCESS_TOKEN, COOKIE_SESSION_DATA } from '@/features/auth/utils/constants';
import { FilesQueryKeys } from '@/features/files/api/query-keys';
import api from '@/utils/fetch';
import { CustomerQueryKeys } from './query-keys';
import { CustomerProfileResponse } from '../dtos/api.types';
function getQueryFn({
  locale,
  user_customer_uuid,
  token
}: {
  locale: string;
  token: string;
  user_customer_uuid: string;
}) {
  return async () => {
    const response = await api.get(`/customer/${user_customer_uuid}`, {
      headers: {
        'Accept-Language': locale,
        Authorization: `Bearer ${token}`
      }
    });
    return {
      data: response.data
    };
  };
}
function getQueryKeys({
  locale,
  user_customer_uuid,
  token
}: {
  locale: string;
  token: string;
  user_customer_uuid: string;
}) {
  return [CustomerQueryKeys.Profile, locale, FilesQueryKeys.Avatar, user_customer_uuid, token];
}
export function useGetCustomerProfile() {
  const {
    locale
  } = useRouter();
  const {
    sessionData
  } = useAuth();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);
  return useQuery<{
    data: CustomerProfileResponse;
  }, Error>({
    queryKey: getQueryKeys({
      locale: locale || 'bg',
      user_customer_uuid: sessionData?.user_customer_uuid!,
      token: token!
    }),
    queryFn: getQueryFn({
      locale: locale || 'bg',
      user_customer_uuid: sessionData?.user_customer_uuid!,
      token: token!
    }),
    enabled: !!token && sessionData?.user_customer_uuid !== undefined
  });
}

//TODO: fix any
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function prefetchGetCustomerProfile(context: any) {
  const queryClient = new QueryClient();
  const locale = context.locale || 'bg';
  const accessToken = context.req.cookies[COOKIE_ACCESS_TOKEN];
  const sessionToken = context.req.cookies[COOKIE_SESSION_DATA];
  const sessionData = JSON.parse(atob(sessionToken!)); // Default to 'bg' if locale is undefined

  await queryClient.prefetchQuery({
    queryKey: getQueryKeys({
      locale: locale,
      user_customer_uuid: sessionData.user_customer_uuid,
      token: accessToken
    }),
    queryFn: getQueryFn({
      locale: locale,
      user_customer_uuid: sessionData.user_customer_uuid,
      token: accessToken
    })
  });
  return dehydrate(queryClient);
}