import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import LoaderContainer from '@/containers/loader';
import useIsFirstRender from '@/hooks/use-is-first-render';
import useIsMobile from '@/hooks/use-is-mobile';
import { useToast } from '@/hooks/use-toast';
import { BREAKPOINTS } from '@/utils/constants/base';
import { Logger } from '@/utils/logger';
import notificationsApiClient from './api';
import NotificationCard from './components/notification-card';
import NotificationIcon from './components/notification-icon';
import styles from './index.module.css';
type ComponentProps = {
  customerType: 'customer' | 'supplier';
};
const Notifications: React.FC<ComponentProps> = ({
  customerType
}: ComponentProps) => {
  const {
    t
  } = useTranslation();
  const isFirstRender = useIsFirstRender();
  const isMobile = useIsMobile(BREAKPOINTS.md);
  const [notificationTabOpen, setNotificationTabOpen] = useState<boolean>(false);
  const [readAll, setReadAll] = useState<boolean>(false);
  const {
    toast
  } = useToast();
  const {
    data,
    refetch,
    isLoading
  } = notificationsApiClient.useGetNotifications();
  const readNotification = notificationsApiClient.useReadNotification();
  const notificationsResponseData = data?.data;
  const notificationsCount = customerType === 'supplier' ? notificationsResponseData?.stats.supplier_notify_count : notificationsResponseData?.stats.customer_notify_count;
  const newNotifications = notificationsResponseData?.notifications.filter(notification => customerType === 'supplier' ? !!notification.user_supplier : !!notification.user_customer);
  const handleReadNotification = (notificationUuid: string) => {
    readNotification.mutate(notificationUuid, {
      onSuccess: () => {
        refetch();
      },
      onError: error => {
        toast({
          title: 'An error occured.',
          description: error.message
        });
        refetch();
        Logger.captureException(error);
      }
    });
  };
  const handleNotificationTabOpen = () => {
    setNotificationTabOpen(current => !current);
  };
  const notificationItems = newNotifications?.map(notification => {
    return <NotificationCard key={notification.uuid} uuid={notification.uuid} type="success" title={notification.title || 'Notification'} description={notification.description} readAll={readAll} meta={notification.meta} onReadNotification={handleReadNotification} handleNotificationTabOpen={handleNotificationTabOpen} />;
  });
  useEffect(() => {
    if (readAll) {
      setReadAll(false);
    }
  }, [readAll]);
  useEffect(() => {
    if (isFirstRender) return;
    refetch();
    //TODO: check dependency
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTabOpen]);
  const renderNotificationsButton = () => <div className="relative select-none" onClick={() => {
    setNotificationTabOpen(true);
  }} data-sentry-component="renderNotificationsButton" data-sentry-source-file="index.tsx">
      <NotificationIcon data-sentry-element="NotificationIcon" data-sentry-source-file="index.tsx" />
      <div className="font-semibold w-[21px] h-[21px] bg-[#F2CB0C] rounded-full absolute top-[-4px] right-[-4px] text-white text-xs cursor-pointer flex justify-center items-center">
        {isLoading ? <span className="bg-white w-[8px] h-[8px] rounded-full" /> : <span className="mt-[2px]">{notificationsCount}</span>}
      </div>
    </div>;
  const renderNotificationsContent = () => <div className={`max-h-[500px] h-[500px] ${styles.scrollable} ${!!notificationsCount && notificationsCount > 1 ? 'pl-1' : ''}`} data-sentry-component="renderNotificationsContent" data-sentry-source-file="index.tsx">
      <div className="flex justify-between items-center">
        <h4 className="text-lg">{t('NOTIFICATIONS.TITLE')}</h4>
        <Image src="/images/close.svg" width={18} height={18} alt={t('IMAGES.CLOSE')} className="cursor-pointer" onClick={handleNotificationTabOpen} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </div>
      <LoaderContainer isLoading={isLoading} data-sentry-element="LoaderContainer" data-sentry-source-file="index.tsx">
        {!!notificationsCount && notificationsCount > 0 ? <>
            {notificationsCount > 1 ? <div className="rounded-sm border p-4 my-2">
                <p className="text-base">{t('NOTIFICATIONS.TITLE')}</p>
                <p>
                  {t('NOTIFICATIONS.NEW_NOTIFICATIONS_COUNT', {
              count: notificationsCount
            })}
                </p>
                <p>{t('NOTIFICATIONS.NOTIFICATIONS_CHECK')}</p>
                <div className="w-full flex mt-3">
                  <Button variant="outline" className="ml-auto rounded-xs" onClick={() => {
              setReadAll(true);
            }}>
                    {t('NOTIFICATIONS.READ_ALL')}
                  </Button>
                </div>
              </div> : null}
            <ul id="notifications" className="flex flex-col">
              {notificationItems}
            </ul>
          </> : <div className="text-center flex flex-col items-center">
            <Image className="w-full pointer-events-none max-w-[300px]" src="/images/notifications/empty.svg" width={100} height={100} alt={t('IMAGES.EMPTY')} />
            <h4 className="text-lg font-semibold mb-6">{t('NOTIFICATIONS.EMPTY_NOTIFICATIONS')}</h4>
            <p className="text-base">{t('NOTIFICATIONS.NOTIFY_IF_IMPORTANT')}</p>
          </div>}
      </LoaderContainer>
    </div>;
  return <div id="notifications-menu" className="relative" data-sentry-component="Notifications" data-sentry-source-file="index.tsx">
      {isMobile ? <>
          {renderNotificationsButton()}
          <Dialog open={notificationTabOpen} onOpenChange={handleNotificationTabOpen}>
            <DialogContent className="bg-white rounded-sm w-full mt-2 p-4 text-text
                                fixed transition-transform duration-300 ease-in-out">
              {renderNotificationsContent()}
            </DialogContent>
          </Dialog>
        </> : <DropdownMenu open={notificationTabOpen} onOpenChange={handleNotificationTabOpen}>
          <DropdownMenuTrigger asChild>{renderNotificationsButton()}</DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white rounded-sm min-w-[363px] w-[363px] mt-2 p-4 text-text">
            {renderNotificationsContent()}
          </DropdownMenuContent>
        </DropdownMenu>}
    </div>;
};
export default Notifications;