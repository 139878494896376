import Cookies from 'js-cookie';

export const invalidateCookies = () => {
  const allCookies = Cookies.get();
  for (const cookieName in allCookies) {
    Cookies.remove(cookieName);
  }

  setTimeout(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, 1000);
};
