export type PublicMenuRoute = {
  disabled?: boolean;
  id: string;
  name: string;
  url: string;
};

export const publicMenuRoutes: PublicMenuRoute[] = [
  {
    id: 'categories',
    name: 'HEADER.CATEGORIES',
    url: '/categories',
  },
  {
    id: 'suppliers',
    name: 'HEADER.SUPPLIERS',
    url: '/suppliers',
  },
  {
    id: 'pricing',
    name: 'HEADER.PRICING',
    url: '/pricing',
  },
  {
    id: 'about-us',
    name: 'HEADER.ABOUT_US',
    url: '/about-us',
  },
  // {
  //   id: "create-order",
  //   name: "HEADER.CREATE_ORDER",
  //   url: "/create-order",
  // },
];

export const quickLinkRoutes = [
  {
    id: 'home',
    name: 'HOME',
    url: '/',
  },
  {
    id: 'about-us',
    name: 'ABOUT_US',
    url: '/about-us',
  },
  {
    id: 'categories',
    name: 'CATEGORIES',
    url: '/categories',
  },
  {
    id: 'suppliers',
    name: 'SUPPLIERS',
    url: '/suppliers',
  },
  {
    id: 'pricing',
    name: 'PRICING',
    url: '/pricing',
  },
  {
    id: 'careers',
    name: 'CAREERS',
    url: 'https://career.ofertirai.me',
  },

  {
    id: 'create-order',
    name: 'CREATE_ORDER',
    url: '/create-order',
  },

  // {
  //   id: "accounter",
  //   name: "ACCOUNTER",
  //   url: "/accounter",
  // },
];
