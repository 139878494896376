import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { Spinner } from '@/components/ui/spinner';
import useCabinet from '../hooks/use-cabinet';

/**
 * Wrap your component with this, pass the necessary props
 * and it will show a loader until the component is loaded
 */
const LoaderContainer = ({
  isLoading,
  message,
  children,
  size,
  align = 'center',
  className
}: {
  align?: 'center' | 'end' | 'start' | null;
  children: ReactNode;
  className?: string;
  isLoading: boolean;
  message?: string;
  size?: 'large' | 'medium' | 'small' | 'xl';
}) => {
  const {
    t
  } = useTranslation();
  const cabinet = useCabinet();
  return <>
      {isLoading ? <div className={`flex items-${align} flex-col ${cabinet.type} ${size === 'small' ? 'small p-2' : 'normal p-4'} ${className}`}>
          <Spinner size={size} />
          {message ? <div className="pt-8">{t(message)}</div> : ''}
        </div> : <>{children}</>}
    </>;
};
export default LoaderContainer;