import {
  InvalidateQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { COOKIE_ACCESS_TOKEN } from '@/features/auth/utils/constants';
import api from '@/utils/fetch';

import { NotificationsQueryKeys } from './query-keys';
import { NotificationsResponse } from '../dtos/types';

function useGetNotifications() {
  const { locale } = useRouter();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);

  return useQuery<{ data: NotificationsResponse }, Error>({
    queryKey: [NotificationsQueryKeys.All, locale, token],
    queryFn: () =>
      api.get('/notifications', {
        headers: {
          'Accept-Language': locale,
          Authorization: `Bearer ${token}`,
        },
      }),
  });
}

function useReadNotification() {
  const { locale } = useRouter();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);

  const client = useQueryClient();

  return useMutation({
    mutationFn: (notificationUuid: string) =>
      api.put(
        '/read_notification/' + notificationUuid,
        {},
        {
          headers: {
            'Accept-Language': locale,
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    onSuccess: () =>
      client.invalidateQueries([locale, NotificationsQueryKeys.Read] as InvalidateQueryFilters),
  });
}

const apiClient = {
  useGetNotifications,
  useReadNotification,
};

export default apiClient;
