import { useRouter } from 'next/router';
import { useMemo } from 'react';

export type PanelType = 'customer' | 'supplier';

interface RouteTypeInfo {
  className: string;
  navigationImage: string;
  //TODO: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: any[];
  type: PanelType;
  url: string;
}

const useCabinet = (): RouteTypeInfo => {
  const router = useRouter();

  return useMemo(() => {
    const path = router.asPath;
    const isSupplier = path.includes('/supplier');
    const isCustomer = path.includes('/customer');

    if (isSupplier) {
      return {
        className: 'supplier',
        url: '/supplier',
        type: 'supplier',
        navigationImage: 'supplier',
        routes: [],
      };
    } else if (isCustomer) {
      return {
        className: 'customer',
        url: '/customer',
        type: 'customer',
        navigationImage: 'customer',
        routes: [],
      };
    } else {
      return {
        className: '',
        url: '',
        type: 'customer',
        navigationImage: '',
        routes: [],
      };
    }
  }, [router.asPath]);
};

export default useCabinet;
