import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';
import CabinetHeaderAvatarDesktop from '@/components/cabinet-header-avatar-desktop-menu';
import { useGetCustomerProfile } from '@/features/customer/api/use-get-customer-profile';
import Notifications from '@/features/notifications';
import { getCustomerName } from '@/features/profile/utils/helpers';
import { useGetSupplierProfile } from '@/features/supplier/api/use-get-supplier-profile';
import useCabinet from '@/hooks/use-cabinet';
import favoritesIcon from '@public/images/favorites/favorites.svg';
const CabinetHeaderDesktop = ({
  title
}: {
  title: string;
}) => {
  const {
    t
  } = useTranslation();
  const customerProfile = useGetCustomerProfile();
  const supplierProfile = useGetSupplierProfile();
  const cabinet = useCabinet();
  if (!customerProfile?.data?.data) {
    return;
  }
  const {
    avatar_sizes
  } = customerProfile.data.data;
  const customerAvatar = avatar_sizes?.length ? avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || avatar_sizes[0]?.url : null;
  const customerName = getCustomerName(customerProfile.data.data);
  const supplierAvatar = supplierProfile.data?.data?.avatar_sizes?.length ? supplierProfile?.data?.data?.avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || supplierProfile?.data?.data.avatar_sizes[0].url : null;
  const supplierName = supplierProfile.data?.data.name ? supplierProfile.data?.data.name : supplierProfile.data?.data.email;
  const avatar = cabinet.type === 'customer' ? customerAvatar : supplierAvatar;
  return <header className={`sticky top-0 z-50 h-20 bg-white drop-shadow-xl flex items-center justify-around gap-2`} data-sentry-component="CabinetHeaderDesktop" data-sentry-source-file="cabinet-header-desktop.tsx">
      <div className="w-full max-w-screen-2xl flex justify-between items-center px-6 2xl:px-6">
        <div className="flex gap-16 items-center">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="cabinet-header-desktop.tsx">
            <Image src="/images/header/cabinet-logo.svg" alt="ofertirai.me" width={231} height={46} className="md:min-w-[165px] md:w-[165px] xl:w-[219px] xl:w-[219px] transition-all" priority data-sentry-element="Image" data-sentry-source-file="cabinet-header-desktop.tsx" />
          </Link>
          <div className="text-left hidden">
            <h1 className="text-gray-700 text-xl font-base">{t(title)}</h1>
          </div>
        </div>
        <div className="flex gap-x-6 items-center">
          {/* <Button
            variant={cabinet.type === 'customer' ? 'secondary' : 'default'}
            onClick={() => handlePanelChange(cabinet.type)}
           >
            {cabinet.type === 'customer'
              ? t('CABINET.SUPPLIER.DESKTOP')
              : t('CABINET.CUSTOMER.DESKTOP')}
           </Button> */}
          {cabinet.type === 'customer' ? <Link href="/customer/favorites">
              <div className="cursor-pointer bg-white rounded-full h-[40px] w-[40px] min-h-[40px] min-w-[40px] flex items-center justify-center">
                <Image src={favoritesIcon.src} width={16} height={16} alt={t('IMAGES.FAVORITES')} />
              </div>
            </Link> : null}
          <Notifications customerType={cabinet.type} data-sentry-element="Notifications" data-sentry-source-file="cabinet-header-desktop.tsx" />
          <CabinetHeaderAvatarDesktop avatar={avatar} cyrilicName={customerName.cyrilicName || customerName.email} latinName={customerName.latinName || customerName.email} supplierName={supplierName} data-sentry-element="CabinetHeaderAvatarDesktop" data-sentry-source-file="cabinet-header-desktop.tsx" />
        </div>
      </div>
    </header>;
};
export default CabinetHeaderDesktop;