import '@/styles/global.css';
import { GoogleAnalytics } from '@next/third-parties/google';
import { NextComponentType } from 'next';
import type { AppProps } from 'next/app';
import { Roboto } from 'next/font/google';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation, UserConfig } from 'next-i18next';
import { useEffect } from 'react';
import { onCLS, onINP, onLCP } from 'web-vitals';
import { AuthProvider } from '@/features/auth/context/auth';
import { AvatarProvider } from '@/features/files/context/avatar';
import { useReactPixel } from '@/hooks/use-react-pixel';
import ProtectedLayout from '@/layouts/protected';
import PublicLayout from '@/layouts/public';
import { pixelEvents } from '@/utils/constants/pixel';
import ReactQueryProvider from '@/utils/providers/react-query-provider';
import nextI18NextConfig from '../../next-i18next.config.js';
type CustomAppProps = AppProps & {
  Component: NextComponentType & {
    isPrivatePage?: boolean;
    title?: string;
  };
};
const emptyInitialI18NextConfig: UserConfig = {
  i18n: {
    defaultLocale: nextI18NextConfig.i18n.defaultLocale,
    locales: nextI18NextConfig.i18n.locales
  }
};
const roboto = Roboto({
  weight: '400',
  subsets: ['latin']
});
function Ofertirai({
  Component,
  pageProps
}: CustomAppProps) {
  console.info('Current app version: ', process.env.NEXT_PUBLIC_APP_VERSION);
  // const router = useRouter();
  const {
    submitPixelEvent
  } = useReactPixel();
  useEffect(() => {
    submitPixelEvent(pixelEvents.system.pageView);
  }, [submitPixelEvent]);
  useEffect(() => {
    // Log LCP
    onLCP(metric => {
      //eslint-disable-next-line no-console
      console.log('LCP:', metric);
    });

    // Log CLS
    onCLS(metric => {
      //eslint-disable-next-line no-console
      console.log('CLS:', metric);
    });

    // Log INP
    onINP(metric => {
      //eslint-disable-next-line no-console
      console.log('INP:', metric);
    });
  }, []);
  return <ReactQueryProvider data-sentry-element="ReactQueryProvider" data-sentry-component="Ofertirai" data-sentry-source-file="_app.tsx">
      <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
        <AvatarProvider data-sentry-element="AvatarProvider" data-sentry-source-file="_app.tsx">
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <meta name="viewport" content="width=device-width, initial-scale=1.0" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
          </Head>
          <Script src="//code.jivosite.com/widget/N7F0SFVJiR" async data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
          <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_KEY || ''} data-sentry-element="GoogleAnalytics" data-sentry-source-file="_app.tsx" />

          {Component.isPrivatePage ? <ProtectedLayout title={Component.title}>
              <style jsx global>{`
                html {
                  font-family: ${roboto.style.fontFamily};
                }
              `}</style>
              <Component {...pageProps} />
            </ProtectedLayout> : <PublicLayout>
              <style jsx global>{`
                html {
                  font-family: ${roboto.style.fontFamily};
                }
              `}</style>
              <Component {...pageProps} />
            </PublicLayout>}
        </AvatarProvider>
      </AuthProvider>
    </ReactQueryProvider>;
}
export default appWithTranslation(Ofertirai, emptyInitialI18NextConfig);