import {
  BaseAddressInfo,
  CustomerAddress,
  CustomerProfileResponse,
} from '@/features/customer/dtos/api.types';
/**
 * Gets the first letter of every word in a string and returns them capitalized.
 * @param sentence - The input string to process.
 * @returns A string containing the capitalized first letter of each word.
 */
export function getFirstLetters(sentence: string): string {
  if (!sentence) {
    return '';
  }
  const words = sentence.split(' ');
  const firstLetters = words.map(word => word.charAt(0).toUpperCase()).join('');

  return firstLetters.slice(0, 3);
}

export function getAddresses(
  addresses: CustomerAddress[],
  language: 'bg' | 'en',
): Array<{ addressText: string; id: string; name: string }> {
  const addressArray: { addressText: string; id: string; name: string }[] = [];

  for (const address of addresses) {
    let buildAddress = getPropertyPerLanguage(address.city.city_type, language) + ' ';
    buildAddress += getPropertyPerLanguage(address.city, language) + ', ';
    buildAddress +=
      language === 'bg'
        ? 'община '
        : 'municipality ' + getPropertyPerLanguage(address.city.municipality, language) + ', ';
    buildAddress += ' ' + getPropertyPerLanguage(address.street.street_type, language);
    buildAddress += ' ' + getPropertyPerLanguage(address.street, language);
    buildAddress += ' ' + address.house;
    addressArray.push({
      id: address.uuid,
      name: address.name,
      addressText: buildAddress,
    });
  }

  return addressArray;
}

export function getPropertyPerLanguage(object: BaseAddressInfo, language: string) {
  return language === 'en' ? object.name_en : object.name;
}

/**
 * Converts a Unix timestamp to a Date object, adjusting for timezone differences.
 * @param unixTimestamp - The Unix timestamp in seconds
 * @returns A Date object adjusted for the local timezone
 */
export function unixTimestampToDate(unixTimestamp: number): Date {
  const milliseconds = unixTimestamp * 1000;
  const date = new Date(milliseconds);

  // Create a new Date object set to UTC
  const utcDate = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ),
  );

  return utcDate;
}

/**
 * Converts a Date object to a Unix timestamp.
 * @param date - The Date object to convert
 * @returns The Unix timestamp in seconds
 */
export function dateToUnixTimestamp(date: Date): number {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.error('Invalid date provided to dateToUnixTimestamp. Returning current unix timestamp');

    return Date.now();
  }
  const utcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );

  return Math.floor(utcDate.getTime() / 1000);
}

/**
 * Checks if a value is a valid Unix timestamp.
 * @param value - The value to check
 * @returns True if the value is a valid Unix timestamp, false otherwise
 */
export function isValidUnixTimestamp(value: number): boolean {
  return Number.isInteger(value) && value > 0 && value < 8640000000000;
}

/**
 * Helper function that creates a latinName, cyrilicName and email for the supplier
 */
export function getCustomerName(data: CustomerProfileResponse | undefined): {
  cyrilicName: string;
  email: string;
  latinName: string;
} {
  if (!data) {
    return {
      latinName: '',
      cyrilicName: '',
      email: '',
    };
  }
  const latinName =
    data.first_name_latin && data.last_name_latin
      ? `${data.first_name_latin} ${data.last_name_latin}`
      : '';
  const cyrilicName =
    data.first_name_cyrillic && data.last_name_cyrillic
      ? `${data.first_name_cyrillic} ${data.last_name_cyrillic}`
      : '';

  return {
    latinName,
    cyrilicName,
    email: data.email,
  };
}
