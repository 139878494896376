import useIsMobile from '@/hooks/use-is-mobile';
import CabinetHeaderDesktop from './cabinet-header-desktop';
import CabinetHeaderMobile from './cabinet-header-mobile';
const CabinetHeader = ({
  title
}: {
  title: string;
}) => {
  const isMobile = useIsMobile();
  return isMobile ? <CabinetHeaderMobile data-sentry-element="CabinetHeaderMobile" data-sentry-component="CabinetHeader" data-sentry-source-file="cabinet-header.tsx" /> : <CabinetHeaderDesktop title={title} data-sentry-element="CabinetHeaderDesktop" data-sentry-component="CabinetHeader" data-sentry-source-file="cabinet-header.tsx" />;
};
export default CabinetHeader;