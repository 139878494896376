import { useRouter } from 'next/router';
import { useState, useEffect, useCallback, useRef } from 'react';

import { ProfileTypes, SessionData } from '@/features/auth/dtos/auth.type';
import { invalidateCookies } from '@/features/auth/services/authentication';
import { getProfileType, getSessionData } from '@/features/auth/utils/helpers';

const useAuthSession = () => {
  const router = useRouter();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [profileType, setProfileType] = useState<ProfileTypes>(undefined);
  const isRefreshing = useRef(false);

  const signOut = useCallback(() => {
    router.push('/login');
    invalidateCookies();
    setIsLoggedIn(false);
    setSessionData(null);
    setProfileType(undefined);
    isRefreshing.current = false;
  }, [router]);

  const checkLoginStatus = useCallback(async () => {
    const session = getSessionData();

    // Get profile type and add it to the state
    const currentProfileType = getProfileType();

    setProfileType(currentProfileType);

    if (!session) {
      setIsLoggedIn(false);
      setSessionData(null);

      return;
    }

    const currentTime = Date.now();
    const expirationTime = Number(session.expiration_timestamp + '000');

    const timeUntilExpiration = expirationTime - currentTime;
    //If session expired - singout
    if (timeUntilExpiration < 0) {
      signOut();
      // Disable refresh token logic it was agreed with backend to not refresh the token
      // they have some problem with refreshing it, so this is what we will do
      // } else if (timeUntilExpiration < refreshTreshold) {
      //   const refreshToken = Cookies.get(COOKIE_REFRESH_TOKEN);

      //   if (!refreshToken) {
      //     signOut();
      //     return;
      //   }

      //   try {
      //     isRefreshing.current = true; // Set the flag before refreshing
      //     const response = await refreshTokenQuery.mutateAsync();
      //     const { token, refresh_token, session_data } = response?.data;
      //     if (!token || !refresh_token || !session_data) {
      //       throw new Error("Token data from server not found");
      //     }

      //     // Batch our state updates
      //     setUserData({
      //       token,
      //       refresh_token,
      //       session_data,
      //     });

      //     // Update states after successful refresh
      //     setIsLoggedIn(true);
      //     setSessionData(session_data);
      //     isRefreshing.current = false; // Reset the flag after successful refresh
      //   } catch (error) {
      //     Logger.captureException(error);
      //     signOut();
      //   }
    } else {
      setIsLoggedIn(true);
      setSessionData(session);
    }
  }, [signOut]);

  useEffect(() => {
    checkLoginStatus();
    const intervalId = setInterval(checkLoginStatus, 60000); // Check every minute

    return () => {
      clearInterval(intervalId);
      isRefreshing.current = false; // Reset the flag on cleanup
    };
  }, [checkLoginStatus]);

  return {
    status: isLoggedIn,
    sessionData,
    profileType,
    signOut,
    checkLoginStatus,
  };
};

export default useAuthSession;
