import Image from 'next/image';
import { useTranslation } from 'next-i18next';
const NotificationIcon: React.FC = () => {
  const {
    t
  } = useTranslation();
  return <div className="cursor-pointer bg-white rounded-full h-[40px] w-[40px] min-h-[40px] min-w-[40px] flex items-center justify-center" data-sentry-component="NotificationIcon" data-sentry-source-file="notification-icon.tsx">
      <Image src="/images/notifications/bell.svg" width={24} height={24} alt={t('IMAGES.NOTIFICATIONS')} data-sentry-element="Image" data-sentry-source-file="notification-icon.tsx" />
    </div>;
};
export default NotificationIcon;