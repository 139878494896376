import { FC, ReactNode } from 'react';
import CookiePolicyModal from '@/components/cookie-policy-modal';
import { Toaster } from '@/components/ui/toaster';
import Footer from '@/containers/footer';
import PublicHeader from '@/containers/public-header';
const PublicLayout: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  return <>
      <PublicHeader data-sentry-element="PublicHeader" data-sentry-source-file="public.tsx" />
      <main className="main-layout">{children}</main>
      <CookiePolicyModal data-sentry-element="CookiePolicyModal" data-sentry-source-file="public.tsx" />
      <Footer data-sentry-element="Footer" data-sentry-source-file="public.tsx" />
      <Toaster data-sentry-element="Toaster" data-sentry-source-file="public.tsx" />
    </>;
};
export default PublicLayout;