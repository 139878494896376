export const SupplierQueryKeys = {
  Profile: ['supplier-profile'],
  Metrics: ['supplier-metrics'],
  Projects: ['my-projects'],
  Orders: ['supplier-orders'],
  Order: ['supplier-order'],
  PublicProfile: ['public-profile'],
  PublicProjects: ['public-projects'],
  PublicProjectsMutation: ['public-projects-mutation'],
  PublicProject: ['public-project'],
  AllPublicProjects: ['all-public-projects'],
  AllPublicProfiles: ['all-public-profiles'],
};
