import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ResponseError } from '@/types/error';
import api from '@/utils/fetch';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function handleFetch<T>(url: string): Promise<ResponseError | T> {
  try {
    const response = await api.get(url);

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      status: error?.status,
      message: error?.message,
      error: true,
    };
  }
}
