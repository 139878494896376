import { useState, useEffect } from 'react';

import { BREAKPOINTS } from '@/utils/constants/base';

// @todo move the breakpoint into a const
const useIsMobile = (breakpoint = BREAKPOINTS.xl) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    // Clean up
    return () => window.removeEventListener('resize', checkMobile);
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
