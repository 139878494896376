import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useAuth } from '@/features/auth/context/auth';
import { setUserProfileType } from '@/features/auth/utils/helpers';
import useCabinet, { PanelType } from '@/hooks/use-cabinet';
const UserTypeToggle = () => {
  const router = useRouter();
  const {
    sessionData,
    checkLoginStatus
  } = useAuth();
  const cabinet = useCabinet();
  const {
    t
  } = useTranslation();
  const switchToCustomer = () => {
    setUserProfileType('CUSTOMER');
    checkLoginStatus();
    router.push('/customer/dashboard');
  };
  const switchToSupplier = () => {
    setUserProfileType('SUPPLIER');
    checkLoginStatus();
    if (sessionData?.user_supplier_uuid) {
      router.push('/supplier/dashboard');
    } else {
      router.push('/supplier/workspace/create');
    }
  };
  const handlePanelChange = (type: PanelType) => {
    if (type === 'customer') {
      switchToCustomer();
    } else {
      switchToSupplier();
    }
  };
  return <div className="text-base mb-4 grid grid-cols-2" suppressHydrationWarning data-sentry-component="UserTypeToggle" data-sentry-source-file="index.tsx">
      <div className={`border select-none cursor-pointer leading-none rounded-l lg:rounded-l-md p-2 md:p-4 col-span-1 text-center w-full ${cabinet.type === 'customer' ? 'border-customer-green bg-customer-green text-white' : 'bg-transparent text-text'}`} onClick={() => handlePanelChange('customer')}>
        {t('CABINET.CUSTOMER.MOBILE')}
      </div>
      <div className={`border select-none cursor-pointer leading-none rounded-r lg:rounded-r-md p-2 md:p-4 col-span-1 text-center w-full text-text ${cabinet.type === 'supplier' ? 'border-yellow bg-yellow' : 'bg-transparent'}`} onClick={() => handlePanelChange('supplier')}>
        {t('CABINET.SUPPLIER.MOBILE')}
      </div>
    </div>;
};
export default UserTypeToggle;