import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCabinet from '@/hooks/use-cabinet';
import { NotificationType } from '../dtos/types';
type ComponentProps = {
  description: string;
  handleNotificationTabOpen: () => void;
  meta?: string;
  onReadNotification: (notificationUuid: string) => void;
  readAll: boolean;
  title: string;
  type: NotificationType;
  uuid: string;
};
function getNotificationIcon(type: NotificationType) {
  let icon = '';
  switch (type) {
    case 'success':
      icon = '/images/notifications/status/success.svg';
      break;
    case 'warning':
      icon = '/images/notifications/status/warning.svg';
      break;
    case 'fail':
      icon = '/images/notifications/status/fail.svg';
      break;
  }
  return icon;
}
const NotificationCard: React.FC<ComponentProps> = ({
  uuid,
  type,
  title,
  description,
  readAll,
  meta,
  onReadNotification,
  handleNotificationTabOpen
}: ComponentProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const cabinet = useCabinet();
  const handleNotificationClick = () => {
    if (meta) {
      const parsedMeta = JSON.parse(meta);
      const navigateUrl = cabinet.type === 'supplier' ? '/supplier' : '/customer';
      if (parsedMeta?.order_uuid) {
        router.push(`${navigateUrl}/workspace/orders/${parsedMeta.order_uuid}`);
        handleNotificationTabOpen();
      }
    }
  };
  useEffect(() => {
    if (readAll) {
      setIsClosing(true);
    }
  }, [readAll]);
  const handleClose = () => {
    setIsClosing(true);
  };
  const handleTransitionEnd = () => {
    if (isClosing) {
      setIsHidden(true);
      onReadNotification(uuid);
    }
  };
  if (isHidden) return null;
  const titleColor = `text-${type}`;
  const notificationIcon = getNotificationIcon(type);
  return <li className={`rounded-xs border p-4 flex gap-x-3 mb-3 transition-all duration-300 ${isClosing ? 'opacity-0 translate-x-10' : ''} ${meta ? 'cursor-pointer' : ''}`} style={isHidden ? {
    display: 'none'
  } : undefined} onTransitionEnd={handleTransitionEnd} onClick={handleNotificationClick} data-sentry-component="NotificationCard" data-sentry-source-file="notification-card.tsx">
      <div>
        <Image src={notificationIcon} width={24} height={24} className="min-h-[24px] min-w-[24px]" alt={t('IMAGES.STATUS')} data-sentry-element="Image" data-sentry-source-file="notification-card.tsx" />
      </div>
      <div>
        <h5 className={titleColor}>{title}</h5>
        <div>{description}</div>
      </div>
      <div className="ml-auto">
        <Image src="/images/close.svg" width={18} height={18} alt={t('IMAGES.CLOSE')} className="cursor-pointer min-h-[18px] min-w-[18px]" onClick={e => {
        e.stopPropagation();
        handleClose();
      }} data-sentry-element="Image" data-sentry-source-file="notification-card.tsx" />
      </div>
    </li>;
};
export default NotificationCard;