import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
const CookiePolicyModal = () => {
  const {
    i18n
  } = useTranslation();
  const [isPolicyAccepted, setPolicyAccepted] = useState(true);
  useEffect(() => {
    setPolicyAccepted(localStorage?.getItem('cookies_ofertiraime') === 'true');
  }, []);
  const handleAccept = () => {
    localStorage?.setItem('cookies_ofertiraime', 'true');
    setPolicyAccepted(true);
  };
  const handleReject = () => {
    setPolicyAccepted(true); // this will only hide window temporary
  };
  return <>
      {!isPolicyAccepted && <div className="w-full sticky bottom-[5px] pr-[2.5vw] md:right-0 md:bottom-[20px] md:px-10 flex justify-end">
          <div className=" w-[400px] max-w-[95vw] bg-white p-6 rounded-md shadow-xl hover:shadow-md transition-all">
            <div className="text-[#2a3e5b] text-center">
              {i18n.language === 'en' ? `This website uses cookies to improve your experience. By clicking
          “Accept” you are agreeing to our Cookie Policy.` : `Този уебсайт използва бисквитки, за да подобри вашето изживяване. Чрез кликване
          „Приемам“ вие се съгласявате с нашата Политика за бисквитки.`}
            </div>
            <div className="flex gap-2 items-center mt-6 justify-center">
              <div onClick={handleReject} className="border-border cursor-pointer border-[1px] rounded-md h-[40px] w-[120px] text-[#2a3e5b] flex items-center justify-around hover:text-white hover:bg-[#2a3e5b] transition-all hover:border-[#2a3e5b]">
                {i18n.language === 'en' ? 'Reject All' : 'Отказвам'}
              </div>
              <div onClick={handleAccept} className="h-[40px] w-[120px] cursor-pointer flex items-center justify around text-white bg-green-700 border-green-700 border-[1px] flex items-center justify-around rounded-md hover:text-green-800 hover:bg-white transition-all">
                {i18n.language === 'en' ? 'Accept' : 'Приемам'}
              </div>
            </div>
          </div>
        </div>}
    </>;
};
export default CookiePolicyModal;