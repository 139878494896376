import { useQueryClient } from '@tanstack/react-query';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState, useRef } from 'react';
import { ServicesQueryKeys } from '@/features/services/api/query-keys';
import useIsMobile from '@/hooks/use-is-mobile';
const languages = [{
  code: 'en',
  name: 'English',
  flag: '/images/flags/en.svg'
}, {
  code: 'bg',
  name: 'Български',
  flag: '/images/flags/bg.svg'
}
// Add more languages here
];
const LanguageSwitcher: React.FC = () => {
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const router = useRouter();
  const [currentLanguage, setCurrentLanguage] = useState(() => languages.find(lang => lang.code === router.locale) || languages[0]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    t
  } = useTranslation();
  const handleChangeLanguage = (lang: (typeof languages)[0]) => {
    const {
      pathname,
      asPath,
      query
    } = router;
    router.push({
      pathname,
      query
    }, asPath, {
      locale: lang.code
    });
    setCurrentLanguage(lang);
    queryClient.invalidateQueries({
      queryKey: [ServicesQueryKeys.All]
    });
  };
  useEffect(() => {
    const newLang = languages.find(lang => lang.code === router.locale);
    if (newLang && newLang.code !== currentLanguage.code) {
      setCurrentLanguage(newLang);
    }
  }, [router.locale, currentLanguage.code]);
  return isMobile ? <div role="menu" data-sentry-component="LanguageSwitcher" data-sentry-source-file="index.tsx">
      {/* <div className="text-lg cursor-pointer uppercase mb-0 no-underline py-2 flex items-center justify-between w-full">
        Select language
       </div> */}
      {languages.map(lang => <a key={lang.code} onClick={() => handleChangeLanguage(lang)} className="text-lg cursor-pointer uppercase mb-2 no-underline py-2 text-border border-border border-b-2 flex items-center justify-between w-full" role="menuitem" tabIndex={0} onKeyDown={e => e.key === 'Enter' && handleChangeLanguage(lang)}>
          <span className="text-text">{lang.name}</span>
          <Image src={lang.flag} alt={t('IMAGES.LANGUAGE')} width={24} height={24} />
        </a>)}
    </div> : <div ref={dropdownRef} data-sentry-component="LanguageSwitcher" data-sentry-source-file="index.tsx">
      <ul className="flex" role="menu">
        {languages.map((lang, index) => <li key={lang.code} onClick={() => handleChangeLanguage(lang)} role="menuitem" className={`cursor-pointer py-1 px-2 ${index !== languages.length - 1 ? 'border-r' : ''}`}>
            <Image src={lang.flag} alt={`${t('IMAGES.LANGUAGE')} ${lang.name}`} width={32} height={32} className="transition-transform duration-200 ease-in-out hover:scale-110" />
          </li>)}
      </ul>
    </div>;
};
export default LanguageSwitcher;