import axios from 'axios';
import Cookies from 'js-cookie';

import { COOKIE_ACCESS_TOKEN } from '@/features/auth/utils/constants';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    const isForbidden = error.response?.status === 403;
    const requestBaseUrl = error.config?.baseURL;
    const expectedBaseUrl = process.env.NEXT_PUBLIC_API_URL;

    if (isForbidden && requestBaseUrl === expectedBaseUrl) {
      localStorage.clear();
      sessionStorage.clear();
      Cookies.remove(COOKIE_ACCESS_TOKEN);

      if (typeof window !== 'undefined') {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  },
);

export default api;
