import Image from 'next/image';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvatar } from '../../context/avatar';
interface AvatarProps {
  autoSize?: boolean;
  radius?: 'full' | 'rounded';
  size?: number;
  src: string | null | undefined;
}
const AvatarUploader: React.FC<AvatarProps> = ({
  src,
  size = 180,
  autoSize = false,
  radius = 'full'
}) => {
  const {
    t
  } = useTranslation();
  const {
    avatarUrl,
    updateAvatar
  } = useAvatar();
  const [localAvatarSrc, setLocalAvatarSrc] = useState(src || avatarUrl);
  useEffect(() => {
    if (src) {
      setLocalAvatarSrc(src);
      updateAvatar(src);
    } else if (avatarUrl) {
      setLocalAvatarSrc(avatarUrl);
    }
  }, [src, avatarUrl, updateAvatar]);
  return <div className={`relative flex items-center justify-center rounded-full cabinet-header p-[6px]`} style={{
    maxWidth: autoSize ? '100%' : `${size}px`,
    height: autoSize ? '100%' : `${size}px`,
    width: '100%'
  }} data-sentry-component="AvatarUploader" data-sentry-source-file="index.tsx">
      <Image src={localAvatarSrc || '/images/header/account.svg'} alt={t('IMAGES.AVATAR')} layout="fill" objectFit="cover" priority className={`${radius === 'full' ? 'rounded-full' : 'rounded-[25px]'} ${!localAvatarSrc ? 'p-[10%]' : ''}`} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </div>;
};
export default AvatarUploader;