import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
const buttonVariants = cva('inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50', {
  variants: {
    variant: {
      default: 'button customer',
      // go to global.css to check it
      destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
      outline: 'border border-input text-text bg-white border-gray-300 hover:bg-gray-200',
      secondary: 'button supplier',
      // go to global.css to check it
      ghost: 'hover:bg-accent h-[42px] bg-transparent border-text border-[1px] hover:text-accent-foreground hover:bg-text hover:text-white',
      link: 'text-primary underline-offset-4 hover:underline',
      login: 'text-white bg-brand text-base font-light uppercase shadow-xl rounded-xl hover:bg-primary focus:bg-primary',
      newOrder: 'bg-[#4dcef6] text-white shadow-xl hover:shadow-md hover:bg-[#4dcef6]/60 hover:text-black transition-all',
      placeOrder: 'bg-[#f2cb0c] text-text rounded-[5px] border-[#f2cb0c] border-2 hover:bg-white transition-all h-[44px] w-[250px]'
    },
    size: {
      default: 'h-10 px-4 py-2',
      sm: 'h-9 rounded-md px-3',
      md: 'w-[250px]',
      lg: 'h-12 px-8',
      icon: 'h-10 w-10',
      full: 'w-full'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';
export { Button, buttonVariants };