import { ArrowRight } from 'lucide-react';
import { Menu, X, ArrowLeft, ChevronRight, LogOut } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import LanguageSwitcher from '@/components/language-switcher';
import UserTypeToggle from '@/components/user-type-toggle';
import { useAuth } from '@/features/auth/context/auth';
import { useGetCustomerProfile } from '@/features/customer/api/use-get-customer-profile';
import CustomerNavLink from '@/features/customer/components/customer-nav-link';
import Avatar from '@/features/files/components/avatar';
import Notifications from '@/features/notifications';
import { useGetSupplierProfile } from '@/features/supplier/api/use-get-supplier-profile';
import SupplierNavLink from '@/features/supplier/components/supplier-nav-link';
import useCabinet from '@/hooks/use-cabinet';
import { customerCabinetRoutes } from '@/routes/customer-cabinet.routes';
import { PublicMenuRoute, publicMenuRoutes } from '@/routes/public.routes';
import { supplierCabinetRoutes } from '@/routes/supplier-cabinet.routes';
import favoritesIcon from '@public/images/favorites/favorites.svg';
import logo from '@public/images/logo.svg';
const CabinetHeaderMobile = () => {
  const {
    t,
    i18n
  } = useTranslation();
  const [isOpenBaseMenu, setIsOpenBaseMenu] = useState(false);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const customerProfile = useGetCustomerProfile();
  const supplierProfile = useGetSupplierProfile();
  const cabinet = useCabinet();
  const {
    signOut,
    sessionData
  } = useAuth();
  const baseMenuRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (baseMenuRef.current && !baseMenuRef.current.contains(event.target as Node)) {
        setIsOpenBaseMenu(false);
      }
      if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
        setIsOpenUserMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  if (!customerProfile?.data?.data) {
    return;
  }

  // const phone = supplierProfile?.data?.data.phone;
  const {
    avatar_sizes
  } = customerProfile.data.data;
  const customerAvatar = avatar_sizes?.length ? avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || avatar_sizes[0]?.url : null;
  const supplierAvatar = supplierProfile.data?.data?.avatar_sizes?.length ? supplierProfile?.data?.data?.avatar_sizes.find(avatar => avatar.size === 'thumbnail')?.url || supplierProfile?.data?.data.avatar_sizes[0].url : null;
  const avatar = cabinet.type === 'customer' ? customerAvatar : supplierAvatar;
  return <>
      <header className={`sticky top-0 z-50 bg-white h-[72px] drop-shadow-xl flex items-center justify-around gap-2 relative z-10`}>
        <div className="w-full max-w-screen-2xl flex justify-between items-center px-6 py-3">
          <div className="flex items-center gap-x-3">
            <button onClick={() => setIsOpenBaseMenu(!isOpenBaseMenu)} className="text-text z-[1000]">
              {isOpenBaseMenu ? <X size={28} /> : <Menu size={28} />}
            </button>
            <Link href="/" aria-label="Homepage" data-sentry-element="Link" data-sentry-source-file="cabinet-header-mobile.tsx">
              <Image src={logo.src} alt="ofertirai.me" width={40} height={40} className="min-w-[40px] min-h-[40px]" priority data-sentry-element="Image" data-sentry-source-file="cabinet-header-mobile.tsx" />
            </Link>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center justify-end gap-x-2">
                {cabinet.type === 'customer' ? <Link href="/customer/favorites">
                    <div className="cursor-pointer bg-white rounded-full h-[40px] w-[40px] min-h-[40px] min-w-[40px] flex items-center justify-center">
                      <Image src={favoritesIcon.src} width={16} height={16} alt={t('IMAGES.FAVORITES')} />
                    </div>
                  </Link> : null}
                <Notifications customerType={cabinet.type} data-sentry-element="Notifications" data-sentry-source-file="cabinet-header-mobile.tsx" />
              </div>
              {/* <Button
                variant={cabinet.type === 'customer' ? 'secondary' : 'default'}
                onClick={() => handlePanelChange(cabinet.type)}
                className="!text-xs !p-2 !h-auto"
               >
                {cabinet.type === 'customer'
                  ? t('CABINET.SUPPLIER.MOBILE')
                  : t('CABINET.CUSTOMER.MOBILE')}
               </Button> */}
            </div>
            <div className={`flex items-center ${!avatar ? 'p-[4px]' : 'p-0'} min-width bg-white rounded-full gap-4 w-fit cursor-pointer`} onClick={() => setIsOpenUserMenu(!isOpenUserMenu)}>
              <div className="rounded-full bg-white w-[30px] custom-avatar-data">
                <Avatar src={avatar} size={24} autoSize={true} data-sentry-element="Avatar" data-sentry-source-file="cabinet-header-mobile.tsx" />
              </div>
            </div>
          </div>
        </div>
      </header>
      {isOpenBaseMenu || isOpenUserMenu ? <div className="fixed inset-0 bg-black bg-opacity-50 z-[998]" onClick={() => setIsOpenBaseMenu(false)} /> : ''}
      <div ref={baseMenuRef} className={`fixed top-0 left-0 w-[80vw] sm:w-[40vw] h-screen flex flex-col justify-between pb-4 bg-white overflow-x-hidden transition-transform duration-300 ease-in-out z-[999] ${isOpenBaseMenu ? 'translate-x-0' : '-translate-x-full'}`}>
        <nav className="flex flex-col items-start p-4">
          <span className="text-text cursor-pointer" onClick={() => {
          setIsOpenBaseMenu(false);
        }}>
            <ArrowLeft size={24} data-sentry-element="ArrowLeft" data-sentry-source-file="cabinet-header-mobile.tsx" />
          </span>
          {publicMenuRoutes.map((route: PublicMenuRoute) => <Link key={route.id} href={route.url} className={`text-lg uppercase mb-2 no-underline py-2 text-text border-border border-b-2 flex items-center justify-between w-full ${route?.disabled ? 'pointer-events-none opacity-50' : ''}`} onClick={() => setIsOpenBaseMenu(false)} suppressHydrationWarning>
              {t(route?.name || '')}
              <ChevronRight size={24} className="text-text" />
            </Link>)}
        </nav>
        <div className="p-4">
          <LanguageSwitcher data-sentry-element="LanguageSwitcher" data-sentry-source-file="cabinet-header-mobile.tsx" />
        </div>
      </div>
      <div ref={userMenuRef} className={`fixed top-0 right-0 w-[80vw] sm:w-[50vw] h-screen flex flex-col justify-between pb-4 bg-white overflow-x-hidden transition-transform duration-300 ease-in-out z-[999] ${isOpenUserMenu ? 'translate-x-0' : 'translate-x-full'}`}>
        <nav className="flex flex-col items-end pt-4 px-5">
          <span className="text-text cursor-pointer" onClick={() => {
          setIsOpenUserMenu(false);
        }}>
            <ArrowRight size={24} data-sentry-element="ArrowRight" data-sentry-source-file="cabinet-header-mobile.tsx" />
          </span>
          {/* <div className="w-full pl-4 border-text py-4 border-b-[2px]">
            <div className="flex w-full gap-4 items-end">
              <div className="h-[60px] w-[60px] min-w-[60px]">
                <Avatar src={avatar} size={24} autoSize={true} />
              </div>
              <div>
                <div className="text-text text-xl font-normal">{name}</div>
              </div>
            </div>
            <div className="flex w-full gap-4 mt-2 items-end">
              <div className="text-text pl-2 text-xl font-normal">User</div>
              <div className="text-text text-xl font-normal">{phone}</div>
            </div>
           </div> */}
          <div className="w-full mt-4">
            <UserTypeToggle data-sentry-element="UserTypeToggle" data-sentry-source-file="cabinet-header-mobile.tsx" />
            {cabinet.type === 'supplier' ? supplierCabinetRoutes.map(link => <span key={link.name} onClick={() => {
            if (!link?.children) {
              setIsOpenUserMenu(false);
            }
          }}>
                    <SupplierNavLink link={link} sessionSupplierId={sessionData?.user_supplier_uuid} />
                  </span>) : ''}
            {cabinet.type === 'customer' ? customerCabinetRoutes.map(link => <span key={link.name} onClick={() => {
            if (!link?.children) {
              setIsOpenUserMenu(false);
            }
          }}>
                    <CustomerNavLink link={link} />
                  </span>) : ''}
            {sessionData && <span className={`text-base mb-2 py-2 px-4 md:p-4 rounded cursor-pointer bg-transparent text-text flex items-center gap-4 w-full ${cabinet.type === 'customer' ? 'hover:bg-green-400' : 'hover:bg-amber-200'} transition-all`} onClick={signOut} suppressHydrationWarning>
                <LogOut size={24} className="text-text" />
                {i18n.language === 'en' ? 'Exit' : 'Изход'}
              </span>}
          </div>
        </nav>
      </div>
    </>;
};
export default CabinetHeaderMobile;