import { captureException } from '@sentry/nextjs';

export const Logger = {
  //TODO: fix any
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  captureException: (error: any) => {
    if (process.env.NODE_ENV === 'production') {
      captureException(error);
    } else {
      console.error(error);
    }
  },
};
