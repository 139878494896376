import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { pixelEvents } from '@/utils/constants/pixel';

//TODO: fix any
//eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReactPixelType = any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReactPixelEventData = Record<string, any>;

/**
 * Custom hook to integrate React Facebook Pixel with Next.js.
 *
 * @returns {Object} An object containing the `submitPixelEvent` function.
 */
const useReactPixel = () => {
  const router = useRouter();
  const systemEvents = Object.values(pixelEvents.system);

  const [ReactPixel, setReactPixel] = useState<ReactPixelType | null>(null);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_META_PIXEL) {
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          setReactPixel(ReactPixel);
        });
    }
  }, [setReactPixel]);

  /**
   * Tracks a page view event.
   */
  const pageView = useCallback(() => {
    ReactPixel?.init(process.env.NEXT_PUBLIC_META_PIXEL as string);
    ReactPixel?.pageView();

    router.events.on('routeChangeComplete', () => {
      ReactPixel?.pageView();
    });
  }, [ReactPixel, router]);

  /**
   * Submits a pixel event based on the event name.
   *
   * @param {string} eventName - The name of the event to be tracked.
   * @param {ReactPixelEventData} [eventData] - Optional event data to be sent with the event.
   */
  const submitPixelEvent = useCallback(
    (eventName: string, eventData?: ReactPixelEventData) => {
      if (!ReactPixel) return;

      if (eventName === pixelEvents.system.pageView) {
        pageView();

        return;
      }

      if (systemEvents.includes(eventName)) {
        eventData ? ReactPixel.track(eventName, eventData) : ReactPixel.track(eventName);
      }
    },
    [ReactPixel, systemEvents, pageView],
  );

  return {
    submitPixelEvent,
  };
};

export { useReactPixel };
