import { motion, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Toaster } from '@/components/ui/toaster';
import UserTypeToggle from '@/components/user-type-toggle';
import CabinetHeader from '@/containers/cabinet-header';
import Footer from '@/containers/footer';
import { useAuth } from '@/features/auth/context/auth';
import { invalidateCookies } from '@/features/auth/services/authentication';
import CustomerNavLink from '@/features/customer/components/customer-nav-link';
import SupplierNavLink from '@/features/supplier/components/supplier-nav-link';
import useCabinet from '@/hooks/use-cabinet';
import useIsMobile from '@/hooks/use-is-mobile';
import { customerCabinetRoutes } from '@/routes/customer-cabinet.routes';
import { supplierCabinetRoutes } from '@/routes/supplier-cabinet.routes';
const ProtectedLayout: FC<{
  children: ReactNode;
  title?: string;
}> = ({
  children,
  title
}) => {
  const {
    i18n,
    t
  } = useTranslation();
  const {
    status: isLoggedIn,
    checkLoginStatus,
    sessionData
  } = useAuth();
  const router = useRouter();
  const [isChecking, setIsChecking] = useState(true);
  const isMobile = useIsMobile();
  const [asideAnimationComplete, setAsideAnimationComplete] = useState(false);
  const cabinet = useCabinet();
  useEffect(() => {
    const checkAuth = async () => {
      setIsChecking(true);
      await checkLoginStatus();
      setIsChecking(false);
    };
    checkAuth();
  }, [checkLoginStatus]);
  useEffect(() => {
    if (!isChecking && isLoggedIn === false) {
      sessionStorage.setItem('redirectUrl', router.asPath);
      router.push('/login');
    }
    if (isLoggedIn === false) {
      invalidateCookies();
    }
  }, [isChecking, isLoggedIn, router]);
  useEffect(() => {
    if (isMobile) {
      setAsideAnimationComplete(true);
    }
  }, [isMobile]);
  if (isChecking || isLoggedIn === null) {
    return <div className="w-[100vw] h-[100vh] flex justify-around items-center" suppressHydrationWarning>
        {i18n.language === 'bg' ? 'Зареждане...' : 'Loading...'}
      </div>;
  }
  if (isLoggedIn === false) {
    return null;
  }
  const asideVariants = {
    hidden: {
      height: 0,
      opacity: 0
    },
    visible: {
      height: '820px',
      opacity: 1,
      transition: {
        duration: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1
      }
    }
  };
  const linkVariants = {
    hidden: {
      opacity: 0,
      x: -20
    },
    visible: {
      opacity: 1,
      x: 0
    }
  };
  const childrenVariants = {
    hidden: {
      opacity: 0,
      x: 20
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    }
  };
  return <>
      <CabinetHeader title={title || ''} data-sentry-element="CabinetHeader" data-sentry-source-file="protected.tsx" />
      <main className="private-layout">
        <div className="w-full max-w-screen-2xl ml-auto mr-auto px-6 2xl:px-6 flex py-4 relative">
          {isMobile === false && <AnimatePresence>
              <motion.aside className="relative h-full flex flex-col transition-all justify-between mr-6 bg-white pt-4 pb-6 px-4 rounded-md" initial="hidden" animate="visible" variants={asideVariants} onAnimationComplete={() => setAsideAnimationComplete(true)}>
                <div className="mb-2">
                  <UserTypeToggle />
                  {cabinet.type === 'supplier' ? supplierCabinetRoutes.map(link => <motion.div key={link.name} variants={linkVariants}>
                          <SupplierNavLink key={link.name} link={link} sessionSupplierId={sessionData?.user_supplier_uuid} />
                        </motion.div>) : ''}
                  {cabinet.type === 'customer' ? customerCabinetRoutes.map(link => <motion.div key={link.name} variants={linkVariants}>
                          <CustomerNavLink key={link.name} link={link} />
                        </motion.div>) : ''}
                </div>
                {cabinet.type === 'supplier' ? <motion.div variants={linkVariants} className="w-full">
                    <Image src="/images/supplier-cabinet-menu.svg" width={163} height={278} alt={t('IMAGES.SUPPLIER_CABINET_IMAGE')} className="ml-auto mr-auto w-[163px] h-[278px]" />
                  </motion.div> : ''}
                {cabinet.type === 'customer' ? <motion.div variants={linkVariants} className="w-full">
                    <Image src="/images/customer-cabinet-menu.svg" width={163} height={278} alt={t('IMAGES.CUSTOMER_CABINET_IMAGE')} className="ml-auto mr-auto w-[163px] h-[278px]" />
                  </motion.div> : ''}
              </motion.aside>
            </AnimatePresence>}
          <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="protected.tsx">
            {asideAnimationComplete && <motion.div initial="hidden" animate="visible" variants={childrenVariants} className="w-full">
                {children}
              </motion.div>}
          </AnimatePresence>
          <Toaster data-sentry-element="Toaster" data-sentry-source-file="protected.tsx" />
        </div>
      </main>
      <Footer data-sentry-element="Footer" data-sentry-source-file="protected.tsx" />
    </>;
};
export default ProtectedLayout;