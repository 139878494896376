// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');

module.exports = {
  debug: false,
  i18n: {
    locales: ['bg', 'en'],
    defaultLocale: 'bg',
    localeDetection: false,
  },
  react: { useSuspense: false },
  productionBrowserSourceMaps: true,
  ns: ['common', 'myServices', 'workspace'],
  localePath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
};
